import * as React from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { TypographyProps, alignmentClass } from "../typography";
import { cn } from "../../../utils/shorthands";
import { Tooltip, TooltipProps } from "../../overlay/tooltip/tooltip";
import { SpaceXxSmall } from "../../../design-tokens/variables";
import * as scss from "./heading.scss";

export type Headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type HeadingProps = TypographyProps<"alignment" | "lineHeight"> & {
	/** Allows you to specify another kind of header it should LOOK like.
	 * It will still be the same header level regardless semantically. */
	lookalike?: Headings;
	tooltipText?: TooltipProps["content"];
	tooltipPlacement?: TooltipProps["placement"];
} & DataObserveKey &
	VisualComponent;

const wrapper = (props: HeadingProps, x: JSX.Element) => {
	return props.tooltipText ? (
		<div style={{ display: "flex", alignItems: "center", gap: SpaceXxSmall }}>
			{x}
			<Tooltip
				variant={{ type: "text" }}
				content={props.tooltipText}
				placement={props.tooltipPlacement}
				hideContent={true}
			>
				{props.children ?? <></>}
			</Tooltip>
		</div>
	) : (
		x
	);
};

export const H1 = (props: HeadingProps): JSX.Element =>
	wrapper(props, <h1 {...applyProps(props, "h1")} />);
export const H2 = (props: HeadingProps): JSX.Element =>
	wrapper(props, <h2 {...applyProps(props, "h2")} />);
export const H3 = (props: HeadingProps): JSX.Element =>
	wrapper(props, <h3 {...applyProps(props, "h3")} />);
export const H4 = (props: HeadingProps): JSX.Element =>
	wrapper(props, <h4 {...applyProps(props, "h4")} />);
export const H5 = (props: HeadingProps): JSX.Element =>
	wrapper(props, <h5 {...applyProps(props, "h5")} />);
export const H6 = (props: HeadingProps): JSX.Element =>
	wrapper(props, <h6 {...applyProps(props, "h6")} />);

function applyProps(props: HeadingProps, h: Headings) {
	const {
		alignment,
		lineHeight = "multi-line",
		lookalike,
		className,
		id,
		children,
		style,
		"data-observe-key": dataObserveKey,
	} = props;

	return {
		id,
		children,
		style,
		"data-observe-key": dataObserveKey,

		"data-component": h,
		className: cn(
			scss[lookalike ?? h],
			alignment && alignmentClass(alignment),
			lineHeight === "single-line" && scss.lineHeightSingleLine,
			className
		),
	};
}

export function useHeading(headerLevel: Headings, headingProps: HeadingProps): JSX.Element {
	const heading = (() => {
		switch (headerLevel) {
			case "h1":
				return H1;
			case "h2":
				return H2;
			case "h3":
				return H3;
			case "h4":
				return H4;
			case "h5":
				return H5;
			case "h6":
				return H6;
			default:
				return H1;
		}
	})();
	return React.createElement(heading, headingProps);
}
